import { DEFAULT_PAGE } from "@app/constants";
import {
  WithdrawalsResponse,
  WithdrawalStatus,
} from "@app/types/withdrawal.type";
import HttpService from "@core/services/http/http.service";

class _WithdrawalService {
  public get(page = DEFAULT_PAGE, status = "") {
    return HttpService.get<WithdrawalsResponse>("/withdrawals", {
      queryParams: {
        page,
        status,
      },
    });
  }

  public updateStatus(status: WithdrawalStatus, _id: string) {
    return HttpService.patch(`/withdrawals/${_id}`, { body: { status } });
  }
}

const WithdrawalService = new _WithdrawalService();

export default WithdrawalService;
