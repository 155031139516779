import { createBrowserRouter, Outlet } from "react-router-dom";
import SignIn from "./pages/login";
import MainLayout from "./layout/main.layout";
import LogManagement from "./pages/log-management";
import Accounts from "./pages/accounts-management";
import NotFound from "./pages/not-found";

import { isAuthenticated } from "./loaders/authentication";
import { PathnameUrl } from "./constants";
import AccountDetail from "./pages/account-detail-management"; 
import Commons from "./pages/commons-management/commons.page";
import Reacharges from "./pages/recharges-management";
import Withdrawals from "./pages/withdrawals-management";
import ChangePassword from "./pages/change-password";
import Chat from "./pages/chat";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Accounts />,
      },
      {
        path: PathnameUrl.ACCOUNT_MANAGEMENT,
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Accounts />,
          },
          {
            path: PathnameUrl.ACCOUNT_MANAGEMENT_ID,
            element: <AccountDetail />,
          },
        ],
      },
      {
        path: PathnameUrl.CHAT,
        element: <Chat />,
      }, 
      {
        path: PathnameUrl.RECHARGE_MANAGEMENT,
        element: <Reacharges />,
      },
      {
        path: PathnameUrl.WITHDRAWAL_MANAGEMENT,
        element: <Withdrawals />,
      },
      {
        path: PathnameUrl.LOG_MANAGEMENT,
        element: <LogManagement />,
      },
      {
        path: PathnameUrl.SETTINGS,
        element: <Commons />,
      },
      {
        path: PathnameUrl.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/login",
    element: <SignIn />,
    loader: isAuthenticated,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
