import AccountService from "@app/services/http/account.service";
import { AccountPassword } from "@app/types";
import { createRef } from "react";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import Button from "@app/components/button";
import { passwordValid } from "@app/validations/password.validation";
import useObservable from "@core/hooks/use-observable.hook";
import { addToast } from "@app/components/toast/toast.service";

function ChangePassword() {
  const formRef = createRef<FormikContextType<AccountPassword>>();
  const { subscribeOnce } = useObservable();

  const handleSubmit = (value: AccountPassword) => { 
    subscribeOnce(AccountService.changePassword(value), () => {
      addToast({
        text: "Đổi mật khẩu thành công",
        status: "valid",
        position: "top-right",
      });
    });
  };

  return (
    <div className="w-[70%] mx-auto p-4 text-[#EAECEF] pb-[64px] h-[100vh]">
      <h1 className="my-4 text-center font-bold text-[#F0B90B] text-[20px]">
        Đổi Mật Khẩu
      </h1>
      {/* formik */}
      <Formik
        initialValues={{ oldPassword: "", password: "", confirmPassword: "" }}
        onSubmit={handleSubmit}
        innerRef={formRef}
        validationSchema={passwordValid}
        validateOnChange
        validateOnBlur
      >
        <Form>
          <FormControl name="oldPassword">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder="Mật khẩu cũ..."
              inputElement={<i className="fa fa-lock text-[#c0c4cc]"></i>}
              errorClassName="text-[#F56C6C] text-xs"
              type="password"
            />
          </FormControl>
          <FormControl name="password">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder="Mật khẩu..."
              inputElement={<i className="fa fa-lock text-[#c0c4cc]"></i>}
              errorClassName="text-[#F56C6C] text-xs"
              type="password"
            />
          </FormControl>
          <FormControl name="confirmPassword">
            <Input
              width="auto"
              className="!w-[98%] !max-w-none mb-[22px]"
              inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
              placeholder="Nhập lại mật khẩu..."
              inputElement={<i className="fa fa-lock text-[#c0c4cc]"></i>}
              errorClassName="text-[#F56C6C] text-xs"
              type="password"
            />
          </FormControl>

          <Button
            type="submit"
            width="auto"
            size="m"
            containerClassName="mt-7 max-sm:!mt-3"
            label="Cập Nhật"
            className="text-sm rounded-[20px] bg-[#fcd535] border-[#181e25] hover:bg-[#181e25] hover:border-[#181e25]"
            labelClassName="font-medium"
          />
        </Form>
      </Formik>
    </div>
  );
}

export default ChangePassword;
