import { DEFAULT_PAGE } from "@app/constants";
import {
  Account,
  AccountPassword,
  AccountsResponse,
} from "@app/types/account.type";
import { BetStatus, QueryParams } from "@app/types/bank.type";
import HttpService from "@core/services/http/http.service";

class _AccountService {
  public get(page = DEFAULT_PAGE, search: QueryParams) {
    return HttpService.get<AccountsResponse>("/accounts", {
      queryParams: {
        page,
        ...search,
      },
    });
  }

  public getById(id: string) {
    return HttpService.get<Account>(`/accounts/profile?_id=${id}`);
  }

  public updateBetState(account_id: string, bet_state: BetStatus) {
    return HttpService.patch<Account>(
      `/accounts/update-bet-state/${account_id}`,
      {
        body: {
          bet_state,
        },
      }
    );
  }

  public changePassword(accountPassword: AccountPassword) {
    return HttpService.patch<Account>("/accounts/change-password", {
      body: {
        ...accountPassword,
      },
    });
  }

  public updateAccountById(
    account_id: string,
    bodyUpdate: { is_kyc?: boolean; balance?: number; password?: string }
  ) {
    return HttpService.patch<Account>("/accounts/" + account_id, {
      body: {
        ...bodyUpdate,
      },
    });
  }

  public deleteAccountById(id: string) {
    return HttpService.delete<any>("/accounts/" + id);
  }
}

const AccountService = new _AccountService();

export default AccountService;
