import { DEFAULT_PAGE } from "@app/constants";
import { RechargesResponse } from "@app/types/recharge.type";
import HttpService from "@core/services/http/http.service";

class _RechargeService {

  public get(page = DEFAULT_PAGE, status="") {
    return HttpService.get<RechargesResponse>("/recharges", {
      queryParams: {
        page,
        status,
      },
    });
  }

  public approve(_id: string) {
    return HttpService.patch(`/recharges/admin-confirm/${_id}`);
  }

  public delete(_id: string) {
    return HttpService.delete(`/recharges/${_id}`);
  }
}

const RechargeService = new _RechargeService();

export default RechargeService;
