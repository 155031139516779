import { DEFAULT_DATE_TIME_FORMAT } from "@app/constants";
import { Environment } from "@environments/environment";
import dayjs from "dayjs";

export const formatMoneyVN1 = (
  money: number | string,
  options?: Intl.NumberFormatOptions
) => {
  return Intl.NumberFormat("vi-VN", options).format(Number(money));
};

export const formatMoneyUSDT = (
  money: number | string,
  options?: Intl.NumberFormatOptions
) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2, // thường dùng 2 chữ số thập phân cho USDT
    ...options,
  }).format(Number(money));
};

export const formatMoneyVN = (
  money: number | string,
  options?: Intl.NumberFormatOptions
) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2, // thường dùng 2 chữ số thập phân cho USDT
    ...options,
  }).format(Number(money));
};

export const formatDate = (
  dateString: string | number,
  format = DEFAULT_DATE_TIME_FORMAT
) => {
  if (dateString && dayjs(dateString).isValid()) {
    return dayjs(dateString).format(format);
  }

  return dateString;
};

export const isNumber = (value: any) => {
  return typeof value === "number";
};

export const downloadFile = (
  data: any,
  dataType?: string,
  fileName?: string
) => {
  const binaryData = [];

  binaryData.push(data);
  const downloadLink = document.createElement("a");

  downloadLink.href = window.URL.createObjectURL(
    new Blob(binaryData, { type: dataType })
  );

  if (fileName) downloadLink.setAttribute("download", fileName);

  downloadLink.click();
};

export const playNotificationSound = () => {
  const audio = new Audio(Environment.SOCKET_URL + "/sounds/noti.mp3");

  audio.play().catch(() => {});
};
