import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { createRef } from "react";
import { FormControl } from "../form-control";
import Input from "../input";
import { MessageRequest } from "@app/types";

function CreateMessageTempModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<MessageRequest>>();

  const handleSubmit = (values: MessageRequest) => {
    portalDialogRef.close({ ...values });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] h-auto p-5">
        <div className="text-[34px] pr-5 mb-6">Mẫu Câu</div>
        <Formik
          initialValues={{ text: "" }}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <FormControl name="text">
              <label
                htmlFor="text"
                className="block text-sm/6 font-medium text-gray-900 mb-2"
              >
                Message
              </label>
              <Input
                id="text"
                width="auto"
                className="!w-[98%] !max-w-none mb-[22px]"
                inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                placeholder="..."
                errorClassName="text-[#F56C6C] text-xs"
              />
            </FormControl>
            <button
              type="submit"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              THÊM MỚI
            </button>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateMessageTempModal;
