import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { DEFAULT_PAGE, DEFAULT_TOTAL_PAGES } from "@app/constants";
import LogService from "@app/services/http/log.service";
import { Log } from "@app/types";
import { formatDate } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import { ChangeEvent, useEffect, useState } from "react";
import { Subject, debounceTime } from "rxjs";

function LogManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [logs, setLogs] = useState<Log[]>([]);
  const [search, setSearch] = useState("");

  const searchSubject = new Subject<string>();

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    searchSubject.pipe(debounceTime(300)).subscribe((data) => {
      setSearch(data);
    });
  }, [searchSubject]);

  useEffect(() => {
    subscribeUntilDestroy(LogService.getLogs(page, search), (res) => {
      if (res.data.length) {
        setLogs(res.data);
        setTotalPages(res.total_page);
      } else {
        setLogs([]);
        setTotalPages(DEFAULT_TOTAL_PAGES);
      }
    });
  }, [page, search]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchSubject.next(e.target.value);
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <h1 className="text-3xl font-bold ml-4 mb-6">Lịch sử</h1>
      <div className="pb-2 px-2">
        <div className="ml-2 flex gap-2 items-center">
          <p>Tìm kiếm</p>
          <Input
            className="mt-0 ml-2 max-w-[320px]"
            placeholder="Nhập nội dung..."
            onChange={onFilterChange}
          />
        </div>
        <div className="px-2 overflow-x-auto mt-2">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead className="text-white">
                <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                  <th className="p-3 text-left w-32">ID</th>
                  <th className="p-3 text-left w-96">Log</th>
                  <th className="p-3 text-left w-40">Thời gian log</th>
                </tr>
              </thead>
              <tbody>
                {!!logs.length &&
                  logs.map((log) => (
                    <tr
                      key={log._id}
                      className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                    >
                      <td className="p-3">{log._id}</td>
                      <td className="p-3 whitespace-pre-line">{log.message}</td>
                      <td className="p-3">{formatDate(log.createdAt)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!logs.length && (
              <div className="flex justify-center">Không có dữ liệu</div>
            )}
          </div>
        </div>
        {totalPages > 0 && (
          <div className="flex justify-center mt-3">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LogManagement;
