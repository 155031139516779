import { MenuItem } from "@app/types";
import { NavLink } from "react-router-dom";

interface MenuItemProps extends MenuItem {
  onMenuItemClick: () => void;
}

function MenuLinkItem({ linkTo, label, icon: Icon, onMenuItemClick }: MenuItemProps) {
  return (
    <NavLink
      to={linkTo}
      onClick={onMenuItemClick}
      className={({ isActive }) => {
        let className =
          "h-[50px] flex item-center hover:bg-[#2b3139] text-[#EAECEF] rounded-[22px] mt-1 mb-1";

        if (isActive) {
          className += " bg-[#2b3139]";
        }

        return className;
      }}
    >
      <div className="flex gap-2 items-center justify-start pl-2 w-full">
        <div className="">
          <Icon className="text-[20px] text-center" />
        </div>
        <div className="text-[14px] text-center">{label}</div>
      </div>
    </NavLink>
  );
}

export default MenuLinkItem;
