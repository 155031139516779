export enum SystemMessage {
  UNKNOWN_ERROR = "Something's wrong",
  DO_NOT_PERMISSION = "Forbiden",
  SUCCESS = "Thành Công",
  CONFIRM_DELETE = "Bạn có chắc chắn muốn xóa nội dung này?",
  CONFIRM_DELETE_BANK = "Bạn có chắc chắn muốn xóa ngân hàng này?",
  DELETE_SUCCESSFULLY = "Xóa thành công",
  ADD_SUCCESSFULLY = "Tạo thành công",
  UPDATE_SUCCESSFULLY = "Cập nhật thành công",
}
