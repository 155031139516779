import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import { addToast } from "@app/components/toast/toast.service";
import { SystemMessage } from "@app/constants";
import SettingService from "@app/services/http/setting.service";
import { Setting, UpdateSettingsPayload } from "@app/types/setting.type";
import { formatMoneyVN1 } from "@app/utils/util";
import { settingValidationSchema } from "@app/validations/setting.validation";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { Form, Formik, FormikContextType } from "formik";
import { createRef, useEffect, useState } from "react";

function Commons() {
  const formRef = createRef<FormikContextType<UpdateSettingsPayload>>();
  const [settings, setSettings] = useState<Setting | null>(null);
  const [update, forceUpdate] = useForceUpdate();
  const [rate, setRate] = useState(0);

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(SettingService.get(), (res) => {
      if (res) {
        setSettings(res);
        setRate(res.exchange_rate);
      } else {
        setSettings(null);
      }
    });
  }, [update]);

  const handleSubmit = async (payload: UpdateSettingsPayload) => {
    SettingService.update(payload).subscribe(() => {
      addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
      forceUpdate();
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <h1 className="text-3xl font-bold ml-4 mb-6">Cài đặt</h1>

      <div className="max-w-[500px] ml-4">
        <Formik
          initialValues={{ exchange_rate: settings?.exchange_rate || 0 }}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validationSchema={settingValidationSchema}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <FormControl name="exchange_rate">
              <label
                htmlFor="exchangeRate"
                className="block text-sm/6 font-medium text-gray-900 mb-2"
              >
                Tỷ giá. USDT = VND / Tỷ giá
              </label>
              <Input
                id="exchangeRate"
                width="auto"
                className="!w-[98%] !max-w-none mb-[2px]"
                inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                placeholder="Tỷ giá"
                errorClassName="text-[#F56C6C] text-xs"
                value={String(settings?.exchange_rate || 0)}
                onChange={(e) => setRate(+e.target.value)}
              />
            </FormControl>
            <div className="mb-4">{formatMoneyVN1(rate)}</div>
            <div className="mb-4">
              Ví dụ: Để có 1000 USDT cần nạp {formatMoneyVN1(1000 * rate)} VNĐ
            </div>
            <button
              type="submit"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              CẬP NHẬT
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default Commons;
