import * as Yup from "yup";

export const passwordWithdrawValid = Yup.object().shape({
  password: Yup.string()
    .min(6, "Tối thiểu 6 ký tự")
    .max(30, "Tối đa 40 ký tự")
    .matches(/^[a-zA-Z0-9@$!%*.?&]{6,30}$/, "Chỉ bao gồm a-z, A-Z, 0-9"),
  confirmPassword: Yup.string()
    .required("Không được để trống")
    .oneOf([Yup.ref("password"), null], "Mật khẩu không khớp"),
});

export const passwordValid = Yup.object().shape({
  oldPassword: Yup.string().required("Không được để trống"),
  password: Yup.string()
    .min(6, "Tối thiểu 6 ký tự")
    .max(30, "Tối đa 40 ký tự")
    .matches(/^[a-zA-Z0-9@$!%*.?&]{6,30}$/, "Chỉ bao gồm a-z, A-Z, 0-9"),
  confirmPassword: Yup.string()
    .required("Không được để trống")
    .oneOf([Yup.ref("password"), null], "Mật khẩu không khớp"),
});
