export interface Bank {
  _id: string,
  bank_name: string,
  bank_number: string,
  bank_owner: string,
  bank_img: string
}

export interface CreateBankPayload {
  bank_name: string,
  bank_number: string,
  bank_owner: string,
  bank_img: string,
}

export interface QueryParams {
  uid: string;
  phone: string;
  bet_state: string;
}

export enum BetStatus {
  WIN = "win",
  LOSS = "loss",
  RANDOM = "random",
}
