import Pagination from "@app/components/pagination";
import { addToast } from "@app/components/toast/toast.service";
import {
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  SystemMessage,
} from "@app/constants";
import RechargeService from "@app/services/http/recharge.service"; 
import { Recharge, RechargeStatus } from "@app/types/recharge.type";
import { formatDate, formatMoneyVN, formatMoneyVN1 } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";

function Recharges() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [rechares, setRechares] = useState<Recharge[]>([]);
  const [update, forceUpdate] = useForceUpdate();
  const [currentTab, setCurrentTab] = useState<RechargeStatus | string>("");

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(RechargeService.get(page, currentTab), (res) => {
      if (res.data.length) {
        setRechares(res.data);
        setTotalPages(res.total_page);
      } else {
        setRechares([]);
        setTotalPages(DEFAULT_TOTAL_PAGES);
      }
    });
  }, [page, currentTab, update]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  // const handleDeleteClick = (_id: string) => {
  //   if (_id) {
  //     const confirmModalObs = openPortalDialog(ConfirmModal, {
  //       message: SystemMessage.CONFIRM_DELETE,
  //     });

  //     confirmModalObs.afterClosed().subscribe((data) => {
  //       if (data?.isAccept) {
  //         RechargeService.delete(_id).subscribe(() => {
  //           addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
  //           forceUpdate();
  //         });
  //       }
  //     });
  //   }
  // };

  const handleGetStatus = (status: string) => {
    switch (status) {
      case RechargeStatus.CANCEL:
        return {
          text: "Bị hủy",
          color: "text-red-500",
        };
      case RechargeStatus.PENDING:
        return {
          text: "Đang chờ",
          color: "text-yellow-500",
        };
      case RechargeStatus.SUCCESS:
        return {
          text: "Thành công",
          color: "text-green-500",
        };
      default:
        return {
          text: status,
          color: "text-[#409EFF]",
        };
    }
  };

  const handleChangeTab = (status: RechargeStatus | string) => {
    setCurrentTab(status);
    setPage(1);
  };

  const tabs = [
    { label: "Tất cả", status: "" },
    { label: "Thành công", status: RechargeStatus.SUCCESS },
    { label: "Đang chờ", status: RechargeStatus.PENDING },
    { label: "Hủy (Do User Hủy)", status: RechargeStatus.CANCEL },
    { label: "Hết hạn 15p", status: RechargeStatus.TIME_OUT },
  ];

  const handleApproveClick = (recharge: Recharge) => {
    RechargeService.approve(recharge._id).subscribe(() => {
      addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
      setPage(1);
      forceUpdate();
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <h1 className="text-3xl font-bold ml-4 mb-6">Quản lý nạp tiền</h1>
      <div className="pb-2 px-2">
        <div className="ml-2 flex gap-2 items-center">
          <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className="me-2 cursor-pointer"
                onClick={() => handleChangeTab(tab.status)}
              >
                <p
                  className={`inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 ${
                    currentTab === tab.status
                      ? "text-blue-600 bg-gray-100 dark:bg-gray-800 dark:text-blue-500"
                      : ""
                  }`}
                >
                  {tab.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="px-2 overflow-x-auto mt-2">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead className="text-white">
                <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                  {/* <th className="p-3 text-left w-32">ID</th> */}
                  <th className="p-3 text-left w-32">Chủ tài khoản</th>
                  <th className="p-3 text-left w-32">Số dư giao ngay</th>
                  <th className="p-3 text-left w-32">Số dư ví</th>  
                  <th className="p-3 text-left w-42">Số tiền nạp</th>
                  <th className="p-3 text-left w-42">Nội dung CK</th>
                  <th className="p-3 text-left w-32">Trạng thái</th>
                  <th className="p-3 text-left w-32">Thời gian</th>
                  <th className="p-3 text-left w-48"></th>
                </tr>
              </thead>
              <tbody>
                {!!rechares.length &&
                  rechares.map((recharge) => (
                    <tr
                      key={recharge._id}
                      className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                    >
                      {/* <td className="p-3">{recharge._id}</td> */}
                      <td className="p-3 whitespace-pre-line">
                        {recharge.account.phone}
                      </td>
                      <td className="p-3 whitespace-pre-line">
                        {formatMoneyVN(recharge.account.balance)} USDT
                      </td>
                      <td className="p-3 whitespace-pre-line">
                        {formatMoneyVN(recharge.account.balance_wallet)} USDT
                      </td> 
                      <td className="p-3 text-green-500">
                        {formatMoneyVN1(recharge.money)} VNĐ
                      </td>
                      <td className="p-3">{recharge.content}</td>
                      <td
                        className={`p-3 ${
                          handleGetStatus(recharge.status).color
                        }`}
                      >
                        {handleGetStatus(recharge.status).text}
                      </td>
                      <td className="p-3">{formatDate(recharge.createdAt)}</td>
                      <td className="p-3 text-center">
                        <div className="flex gap-3 items-center justify-end">
                          {recharge.status !== RechargeStatus.SUCCESS && (
                            <button
                              type="button"
                              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              onClick={() => handleApproveClick(recharge)}
                            >
                              Đồng ý
                            </button>
                          )}
                          {/* <button
                            type="button"
                            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                            onClick={() => handleDeleteClick(recharge._id)}
                          >
                            Xóa
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!rechares.length && (
              <div className="flex justify-center">Không có dữ liệu</div>
            )}
          </div>
        </div>
        {totalPages > 0 && (
          <div className="flex justify-center mt-3">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Recharges;
