import { MenuItem } from "@app/types/common.type";
import { TfiUser } from "react-icons/tfi"; 
import { FaRocketchat } from "react-icons/fa";
import { PiHandWithdrawThin, PiHandDepositLight} from "react-icons/pi";
import { GoHistory } from "react-icons/go";
import { TfiSettings } from "react-icons/tfi";
import { RiLockPasswordFill } from "react-icons/ri"; 


export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DEFAULT_MINUTES_SECONDS_FORMAT = "mm:ss";

export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_PAGES = 1;

export const ACCESS_TOKEN_KEY = "access_token";
export const CURRENT_ACCOUNT = "current_account";
export const USER_INFO_KEY = "user_info";

export enum Role {
  ADMIN = "admin",
  USER = "user",
}

export enum PathnameUrl {
  ACCOUNT_MANAGEMENT = "/account-management",
  ACCOUNT_MANAGEMENT_ID = "/account-management/:id", 
  RECHARGE_MANAGEMENT = "/recharge-management",
  WITHDRAWAL_MANAGEMENT = "/withdrawal-management",
  LOG_MANAGEMENT = "/log-management",
  SETTINGS = "/settings",
  LOGIN = "/login",
  CHANGE_PASSWORD = "/change-password",
  CHAT = "/chat",
}

export enum LabelAdmin {
  ACCOUNT_MANAGEMENT = "Tài Khoản", 
  RECHARGE_MANAGEMENT = "Nạp Tiền",
  WITHDRAWAL_MANAGEMENT = "Rút Tiền",
  LOG_MANAGEMENT = "Lịch sử",
  SETTINGS = "Cài đặt",
  CHANGE_PASSWORD = "Đổi mật khẩu",
  CHAT = "Chat",
}

export const Menu: readonly MenuItem[] = [
  {
    linkTo: PathnameUrl.ACCOUNT_MANAGEMENT,
    icon: TfiUser,
    label: LabelAdmin.ACCOUNT_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.CHAT,
    icon: FaRocketchat,
    label: LabelAdmin.CHAT,
  }, 
  {
    linkTo: PathnameUrl.RECHARGE_MANAGEMENT,
    icon: PiHandDepositLight,
    label: LabelAdmin.RECHARGE_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.WITHDRAWAL_MANAGEMENT,
    icon: PiHandWithdrawThin,
    label: LabelAdmin.WITHDRAWAL_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.LOG_MANAGEMENT,
    icon: GoHistory,
    label: LabelAdmin.LOG_MANAGEMENT,
  },
  {
    linkTo: PathnameUrl.SETTINGS,
    icon: TfiSettings,
    label: LabelAdmin.SETTINGS,
  },
  {
    linkTo: PathnameUrl.CHANGE_PASSWORD,
    icon: RiLockPasswordFill,
    label: LabelAdmin.CHANGE_PASSWORD,
  },
];
