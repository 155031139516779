import ConfirmModal from "@app/components/confirm-modal";
import CreateMessageTempModal from "@app/components/create-message-temp/create-message-temp";
import ImageModal from "@app/components/image-modal";
import Pagination from "@app/components/pagination";
import { DEFAULT_PAGE, DEFAULT_TOTAL_PAGES, SocketEvent } from "@app/constants";
import ChatService from "@app/services/http/chat.service";
import MessageService from "@app/services/http/message.service";
import MessageTempService from "@app/services/http/message.temp.service";
import RoomService from "@app/services/http/room.service";
import { openPortalDialog } from "@app/services/modal.service";
import SocketService from "@app/services/socket.service";
import {
  ChatImageRequest,
  CurrentRoom,
  Message,
  MessageTemp,
  Room,
} from "@app/types";
import { formatDate, playNotificationSound } from "@app/utils/util";
import { Images } from "@assets/images";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { Environment } from "@environments/environment";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Subscription } from "rxjs";

function Chat() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [currentRoom, setCurrentRoom] = useState<CurrentRoom>();

  const [pageMessage] = useState(DEFAULT_PAGE);
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState("");
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const refInputMessage = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const [messagesTemp, setMessagesTemp] = useState<MessageTemp[]>();

  const { subscribeOnce } = useObservable();
  const [update, forceUpdate] = useForceUpdate();
  const [updateRooms, setUpdateRooms] = useState(false);

  //get template message
  useEffect(() => {
    subscribeOnce(MessageTempService.getMessageTemp(), (data) => {
      setMessagesTemp(data);
    });
  }, [update]);

  //add message temp
  const addMessageTemp = () => {
    const obs = openPortalDialog(CreateMessageTempModal);

    obs.afterClosed().subscribe((messageTemp) => {
      subscribeOnce(MessageTempService.createMessageTemp(messageTemp), () => {
        forceUpdate();
      });
    });
  };

  const deleteMessageTemp = (temp_id: string) => {
    const confirmObs = openPortalDialog(ConfirmModal, {
      message: "Xác nhận xóa",
    });

    confirmObs.afterClosed().subscribe((data) => {
      if (data.isAccept) {
        subscribeOnce(MessageTempService.deleteMessageTemp(temp_id), () => {
          forceUpdate();
        });
      }
    });
  };

  // Handle send message
  const handleSendMessage = async () => {
    if (!message.trim() && !selectedImage) return;

    try {
      if (selectedImage) {
        const requestBody: ChatImageRequest = {
          img: selectedImage,
        };

        subscribeOnce(ChatService.uploadImage(requestBody), (res) => {
          SocketService.emit(SocketEvent.MESSAGE, res.filename);
        });
      }

      // Reset after sending
      setMessage("");
      setSelectedImage(null);
      setPreviewImage(null);
    } catch (error) {
      alert("Gửi ảnh thất bại. Vui lòng thử lại!");
    }

    if (message) {
      SocketService.emit(SocketEvent.MESSAGE, message);
      refInputMessage.current?.focus();

      setMessage("");
    }
  };

  //call rooms
  useEffect(() => {
    subscribeOnce(RoomService.getRooms(), (res) => {
      if (res.data.length) {
        setRooms(res.data);
        setTotalPages(res.total_page);
      } else {
        setRooms([]);
        setTotalPages(DEFAULT_TOTAL_PAGES);
      }
    });
  }, [page, updateRooms]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const joinRoom = (room: string, room_id: string) => {
    SocketService.emit(SocketEvent.JOIN_ROOM, room);
    SocketService.emit(SocketEvent.SEEN, room_id);
    setCurrentRoom({ room, room_id });
    setRooms((pre) => {
      const index = pre.findIndex((v) => v._id == room_id);

      pre[index].message_un_read = 0;

      return pre;
    });
  };

  // get messages when change rooms
  useEffect(() => {
    if (!currentRoom?.room_id) {
      return;
    }

    subscribeOnce(
      MessageService.getMessages(pageMessage, currentRoom?.room_id),
      (res) => {
        setMessages(res.data);

        if (chatBoxRef.current) {
          setTimeout(() => {
            chatBoxRef.current!.scrollTop = chatBoxRef.current!.scrollHeight;
          }, 10);
        }
      }
    );
  }, [currentRoom]);

  // Connect to socket when change room
  const subscription = new Subscription();

  useEffect(() => {
    const sub = SocketService.onMessage().subscribe((data) => {
      if (currentRoom?.room_id) {
        //nếu có tin nhắn của room hiện tại thì đã xem
        SocketService.emit(SocketEvent.SEEN, currentRoom.room_id);
        setRooms((pre) => {
          const index = pre.findIndex((v) => v._id == currentRoom.room_id);

          pre[index].message_un_read = 0;

          return pre;
        });
      }

      if (data.room_id === currentRoom?.room_id) {
        setMessages((pre) => {
          const newMessages = [...pre, data];

          return newMessages;
        });
      }

      if (chatBoxRef.current) {
        setTimeout(() => {
          chatBoxRef.current!.scrollTop = chatBoxRef.current!.scrollHeight;
        }, 10);
      }

      if (data.sender === "user") {
        playNotificationSound();
      }
    });

    subscription.add(sub);

    const sub2 = SocketService.onAnotherMessage().subscribe((data) => {
      if (!currentRoom?.room_id || data.room_id != currentRoom.room_id) {
        playNotificationSound();
        setUpdateRooms((pre) => !pre);
      }
    });

    subscription.add(sub2);
  }, [subscription]);

  useEffect(() => () => subscription.unsubscribe(), [subscription]);

  const deleteRoom = (room_id: string) => {
    subscribeOnce(RoomService.deleteRoom(room_id), () => {
      if (currentRoom?.room_id == room_id) {
        setCurrentRoom({ room: "", room_id: "" });
      }

      setMessages([]);
      setUpdateRooms((pre) => !pre);
    });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const onRemoveImage = () => {
    setSelectedImage(null);
    setPreviewImage(null);
  };

  const onclickOpenImage = (link: string) => {
    const imgModalObs = openPortalDialog(ImageModal, { link });

    imgModalObs.afterClosed().subscribe(() => {});
  };

  return (
    <div className="flex w-full justify-between">
      <div className="w-[20%] flex flex-col">
        {rooms &&
          rooms.map((room) => (
            <div
              className="group flex p-2 justify-between items-center"
              key={room._id}
            >
              <div
                className={clsx(
                  `flex flex-1 justify-between items-center text-white p-4 rounded-lg m-2 ${
                    currentRoom?.room_id == room._id
                      ? "bg-blue-500"
                      : "bg-pri-color2"
                  }`
                )}
                onClick={() => joinRoom(room.room._id, room._id)}
              >
                <div className="flex gap-2">
                  <img
                    src={room.room.avt}
                    className="w-6 rounded-[50%]"
                    alt=""
                  />
                  <div>{room.room.phone}</div>
                </div>
                <div
                  className={clsx(
                    `text-white bg-slate-400 rounded-lg p-1 ${
                      !+room.message_un_read && "hidden"
                    }`
                  )}
                >
                  {room.message_un_read}+
                </div>
              </div>
              <i
                className="fa-solid fa-trash cursor-pointer hidden group-hover:block"
                onClick={() => deleteRoom(room._id)}
              ></i>
            </div>
          ))}
        {totalPages > 0 && (
          <div className="flex justify-center mt-3">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
      <div className="w-[80%] flex flex-col h-screen bg-gray-100">
        {/* Chat Header */}
        <div className="bg-blue-500 text-white py-2 px-4">Chat</div>

        <div className="flex flex-1 overflow-y-auto">
          <div className="flex-1 flex flex-col">
            {/* Chat Messages */}
            <div
              className="flex-1 p-4 overflow-y-auto"
              ref={chatBoxRef}
              // onScroll={handleScroll}
            >
              {messages.map((msg) => (
                <div
                  key={msg._id}
                  className={`flex flex-col mb-4 ${
                    msg.sender === "user" ? "items-start" : "items-end"
                  }`}
                >
                  <div
                    className={`rounded-lg p-2 break-words whitespace-normal w-fit max-w-[500px] ${
                      msg.sender === "admin"
                        ? "bg-gray-300 text-black"
                        : "bg-blue-500 text-white"
                    }`}
                  >
                    {msg.text.includes("-img-") ? (
                      <img
                        className="w-[200px] min-w-[100px]"
                        src={Environment.BASE_API + "/images/chat/" + msg.text}
                        alt=""
                        onClick={() =>
                          onclickOpenImage(
                            Environment.BASE_API + "/images/chat/" + msg.text
                          )
                        }
                      />
                    ) : (
                      msg.text
                    )}
                  </div>
                  <div className="text-[11px]">{formatDate(msg.createdAt)}</div>
                </div>
              ))}
            </div>
            {/* Chat Input */}
            <div className="flex gap-2 items-center relative bg-gray-200">
              {/* Image Picker */}
              <label className="w-[10%] flex relative items-center justify-center bg-gray-200 rounded cursor-pointer">
                <img
                  src={previewImage || Images.Garllery.default}
                  alt="Preview"
                  className={clsx(
                    `max-h-[200px] object-cover rounded ${
                      previewImage ? "w-[90%]" : "w-14"
                    }`
                  )}
                />

                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                />

                {selectedImage && (
                  <img
                    src={Images.RemoveIconThin.default}
                    alt=""
                    className="absolute bg-white rounded-lg top-0 right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      onRemoveImage();
                    }}
                  />
                )}
              </label>
              <input
                type="text"
                ref={refInputMessage}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                className="w-full p-2 border rounded-lg"
                placeholder="Type a message..."
              />
              <button
                onClick={handleSendMessage}
                className="absolute right-4 top-[50%] translate-y-[-50%]"
              >
                <i className="fa-solid fa-paper-plane text-pri-color"></i>
              </button>
            </div>
          </div>

          {/* message template */}
          <div className="flex w-[20%] flex-col items-center justify-between bg-blue-200 p-2">
            <div className="flex-1 flex flex-col gap-1 overflow-y-scroll">
              {messagesTemp &&
                messagesTemp.map((temp) => (
                  <div key={temp._id} className="relative">
                    <textarea
                      className="mr-2"
                      value={temp.text}
                      readOnly={true}
                      onClick={() => setMessage(temp.text)}
                    />
                    <div className="absolute top-0 right-0 flex gap-1">
                      <i
                        onClick={() => deleteMessageTemp(temp._id)}
                        className="fa-solid fa-delete-left"
                      ></i>
                    </div>
                  </div>
                ))}
            </div>
            <div
              onClick={addMessageTemp}
              className="bg-yellow-500 p-2 rounded-[50%]"
            >
              <i className="fa-solid fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
