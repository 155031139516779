import { Account } from "./account.type";

export enum WithdrawalStatus {
  PENDING = "pending",
  SUCCESS = "success",
  REJECT = "reject",
  TIME_OUT = "time_out",
}

export interface Withdrawal {
  _id: string;
  money: number;
  status: WithdrawalStatus;
  account: Account;
  createdAt: string;
  updatedAt: string;
}

export interface WithdrawalsResponse {
  current_page: number;
  total_page: number;
  count: number;
  data: Withdrawal[];
}
