import Pagination from "@app/components/pagination";
import { addToast } from "@app/components/toast/toast.service";
import {
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  SystemMessage,
} from "@app/constants";
import SettingService from "@app/services/http/setting.service";
import WithdrawalService from "@app/services/http/withdrawals.service";
import { Setting } from "@app/types/setting.type";
import { Withdrawal, WithdrawalStatus } from "@app/types/withdrawal.type";
import { formatDate, formatMoneyVN, formatMoneyVN1 } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";

function Withdrawals() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [withdrawals, setWithdrawals] = useState<Withdrawal[]>([]);
  const [settings, setSettings] = useState<Setting | null>(null);
  const [update, forceUpdate] = useForceUpdate();
  const [currentTab, setCurrentTab] = useState<WithdrawalStatus | string>("");

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(WithdrawalService.get(page, currentTab), (res) => {
      if (res.data.length) {
        setWithdrawals(res.data);
        setTotalPages(res.total_page);
      } else {
        setWithdrawals([]);
        setTotalPages(DEFAULT_TOTAL_PAGES);
      }
    });
  }, [page, currentTab, update]);

  useEffect(() => {
    subscribeUntilDestroy(SettingService.get(), (res) => {
      if (res) {
        setSettings(res);
      } else {
        setSettings(null);
      }
    });
  }, [update]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleGetStatus = (status: string) => {
    switch (status) {
      case WithdrawalStatus.REJECT:
        return {
          text: "Bị hủy",
          color: "text-red-500",
        };
      case WithdrawalStatus.TIME_OUT:
        return {
          text: "Quá hạn",
          color: "text-red-500",
        };
      case WithdrawalStatus.PENDING:
        return {
          text: "Đang chờ",
          color: "text-yellow-500",
        };
      case WithdrawalStatus.SUCCESS:
        return {
          text: "Thành công",
          color: "text-green-500",
        };
      default:
        return {
          text: status,
          color: "text-[#409EFF]",
        };
    }
  };

  const tabs = [
    { label: "Tất cả", status: "" },
    { label: "Thành công", status: WithdrawalStatus.SUCCESS },
    { label: "Đang chờ", status: WithdrawalStatus.PENDING },
    { label: "Bị hủy", status: WithdrawalStatus.REJECT },
  ];

  const handleChangeTab = (status: WithdrawalStatus | string) => {
    setCurrentTab(status);
    setPage(1);
  };

  const handleUpdateWithdrawClick = (
    status: WithdrawalStatus,
    withdrawal_id: string
  ) => {
    WithdrawalService.updateStatus(status, withdrawal_id).subscribe(() => {
      addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
      setPage(1);
      forceUpdate();
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <h1 className="text-3xl font-bold ml-4 mb-6">Quản lý rút tiền</h1>
      <div className="pb-2 px-2">
        <div className="ml-2 flex gap-2 items-center">
          <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className="me-2 cursor-pointer"
                onClick={() => handleChangeTab(tab.status)}
              >
                <p
                  className={`inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 ${
                    currentTab === tab.status
                      ? "text-blue-600 bg-gray-100 dark:bg-gray-800 dark:text-blue-500"
                      : ""
                  }`}
                >
                  {tab.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="px-2 overflow-x-auto mt-2">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead className="text-white">
                <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                  <th className="p-3 text-left w-32">ID</th>
                  <th className="p-3 text-left w-32">SĐT</th>
                  <th className="p-3 text-left w-32">Số Tiền USDT</th>
                  <th className="p-3 text-left w-32">Số Tiền VNĐ</th>
                  <th className="p-3 text-left w-32">TT CK</th>
                  <th className="p-3 text-left w-32">Trạng thái</th>
                  <th className="p-3 text-left w-32">Ngày yêu cầu</th>
                  <th className="p-3 text-left w-32"></th>
                </tr>
              </thead>
              <tbody>
                {!!withdrawals.length &&
                  withdrawals.map((withdrawal) => (
                    <tr
                      key={withdrawal._id}
                      className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                    >
                      <td className="p-3">{withdrawal._id}</td>
                      <td className="p-3">{withdrawal.account.phone}</td>
                      <td className="p-3 text-green-500">
                        {formatMoneyVN(withdrawal.money)} USDT
                      </td>
                      <td className="p-3 ">
                        {settings &&
                          formatMoneyVN1(
                            withdrawal.money * (settings.exchange_rate - 100)
                          )}{" "}
                        VNĐ
                      </td>
                      <td>
                        {withdrawal && withdrawal.account && (
                          <div>
                            <div>{withdrawal?.account?.bank_name}</div>
                            <div>{withdrawal?.account?.bank_number}</div>
                            <div>{withdrawal?.account?.bank_owner}</div>
                          </div>
                        )}
                      </td>
                      <td
                        className={`p-3 ${
                          handleGetStatus(withdrawal.status).color
                        }`}
                      >
                        {handleGetStatus(withdrawal.status).text}
                      </td>
                      <td className="p-3">
                        {formatDate(withdrawal.createdAt)}
                      </td>
                      <td className="p-3">
                        {withdrawal.status === "pending" && (
                          <div className="flex gap-1">
                            <button
                              type="button"
                              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              onClick={() =>
                                handleUpdateWithdrawClick(
                                  WithdrawalStatus.SUCCESS,
                                  withdrawal._id
                                )
                              }
                            >
                              Đồng ý
                            </button>
                            <button
                              type="button"
                              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                              onClick={() =>
                                handleUpdateWithdrawClick(
                                  WithdrawalStatus.REJECT,
                                  withdrawal._id
                                )
                              }
                            >
                              Từ chối
                            </button>
                            <button></button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!withdrawals.length && (
              <div className="flex justify-center">Không có dữ liệu</div>
            )}
          </div>
        </div>
        {totalPages > 0 && (
          <div className="flex justify-center mt-3">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Withdrawals;
