import { Setting, UpdateSettingsPayload } from "@app/types/setting.type";
import HttpService from "@core/services/http/http.service";

class _SettingService {
  public get() {
    return HttpService.get<Setting>("/commons");
  }

  public update(payload: UpdateSettingsPayload) {
    return HttpService.patch("/commons", {
      body: { ...payload },
    });
  }
}

const SettingService = new _SettingService();

export default SettingService;
