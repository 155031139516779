import { MessageRequest, MessageTemp } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _MessageTempService {
  public getMessageTemp() {
    return HttpService.get<MessageTemp[]>("/messages-template");
  }

  public createMessageTemp(body: MessageRequest) {
    return HttpService.post<MessageTemp>("/messages-template", {
      body: {
        ...body,
      },
    });
  }

  public deleteMessageTemp(id: string) {
    return HttpService.delete<any>("/messages-template/" + id);
  }
}

const MessageTempService = new _MessageTempService();

export default MessageTempService;
