import * as RemoveIconThin from "@assets/images/svg/remove-icon-thin.svg";
import * as EyeIcon from "@assets/images/svg/eye.svg";
import * as EyeSlashIcon from "@assets/images/svg/eye-slash.svg";
import * as SpriteSortIcon from "@assets/images/svg/sprite-icon-sort.svg";
import * as SpriteIcon from "@assets/images/svg/sprite-icon.svg";
import * as ArrowUpIcon from "@assets/images/svg/arrow-trg-up.svg";
import * as ArrowDownIcon from "@assets/images/svg/arrow-trg-down.svg";
import * as ArrowThinLeftIcon from "@assets/images/svg/arrow-thin-left.svg";
import * as ArrowThinRightIcon from "@assets/images/svg/arrow-thin-right.svg";

import * as Group from "@assets/images/group.png";
import * as BankBuilding from "@assets/images/bank-building.png";
import * as Money from "@assets/images/money.png";
import * as Logo2 from "@assets/images/logo.png";
import * as Chat from "@assets/images/chat.png";
import * as Garllery from "@assets/images/gallery.png";

export const Images = {
  RemoveIconThin,
  EyeIcon,
  EyeSlashIcon,
  SpriteSortIcon,
  SpriteIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowThinLeftIcon,
  ArrowThinRightIcon,

  Group,
  BankBuilding,
  Money,
  Logo2,
  Chat,
  Garllery,
};
