import { useEffect } from "react";
import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";

function ImageModal({ portalDialogRef, portalData }: PortalDialogProps) {
  useEffect(() => {
    // Xử lý khi nhấn phím Esc
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        portalDialogRef.close();
      }
    };

    // Xử lý khi nhấn nút Back
    const handlePopState = () => {
      portalDialogRef.close();
    };

    // Gắn sự kiện
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("popstate", handlePopState);

    // Cleanup khi component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [portalDialogRef]);

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren={false}>
      <div className="flex items-center overflow-hidden group relative cursor-zoom-in w-[350px] sm_max:w-auto sm_max:h-auto]">
        {portalData?.link && (
          <img
            src={portalData.link}
            alt=""
            className="w-full h-auto object-cover transition-transform duration-300 group-hover:scale-110"
          />
        )}
      </div>
    </Modal>
  );
}

export default ImageModal;
