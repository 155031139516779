import { DEFAULT_PAGE } from "@app/constants";
import { RoomResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _RoomService {
  public getRooms(page = DEFAULT_PAGE) {
    return HttpService.get<RoomResponse>("/rooms", {
      queryParams: {
        page,
      },
    });
  }

  public deleteRoom(room_id: string) {
    return HttpService.delete<any>("/rooms/" + room_id);
  }
}

const RoomService = new _RoomService();

export default RoomService;
