import Button from "@app/components/button";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import { addToast } from "@app/components/toast/toast.service";
import {
  ACCESS_TOKEN_KEY,
  PathnameUrl,
  Role,
  SystemMessage,
  USER_INFO_KEY,
} from "@app/constants";
import AuthService from "@app/services/http/auth.service";
import { storeUser } from "@app/store/auth/auth.action";
import { LoginInitialValues } from "@app/types";
import { loginValidationSchema } from "@app/validations";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import { Form, Formik, FormikContextType } from "formik";
import { createRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Login() {
  const formRef = createRef<FormikContextType<LoginInitialValues>>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: LoginInitialValues) => {
    subscribeOnce(
      AuthService.login(values.phone, values.password),
      (loginRes) => {
        if (loginRes.role === Role.ADMIN) {
          StorageService.set(ACCESS_TOKEN_KEY, loginRes.jwt);
          StorageService.setObject(USER_INFO_KEY, {
            _id: loginRes._id,
            phone: loginRes.phone,
          });
          dispatch(storeUser(loginRes));
          navigate(PathnameUrl.ACCOUNT_MANAGEMENT);
        } else {
          addToast({
            text: SystemMessage.DO_NOT_PERMISSION,
            status: "inValid",
          });
        }
      }
    );
  };

  return (
    <div className="flex justify-center h-screen bg-cover bg-no-repeat bg-center bg-[url(@assets/images/background.jpg)]">
      <div className="flex justify-center items-center z-10 xs_max:w-[706px] xs_min:w-[500px]">
        <div className="w-[96%] bg-[#FFFFFFDA] px-4 py-2 rounded-[10px]">
          <div className="mb-[7px] mt-3">
            <img
              className="w-48 max-sm:w-32 mx-auto"
              src={Images.Logo2.default}
              alt=""
            />
          </div>
          <div className="text-[12.8px] text-[#6C757D] mt-1 text-center mb-7">
            Giao dịch ở bất cứ đâu
          </div>
          <Formik
            initialValues={{ phone: "", password: "" }}
            onSubmit={handleSubmit}
            innerRef={formRef}
            validationSchema={loginValidationSchema}
            validateOnChange
            validateOnBlur
          >
            <Form>
              <FormControl name="phone">
                <Input
                  width="auto"
                  className="!w-[98%] !max-w-none mb-[22px]"
                  inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                  placeholder="Số Điện Thoại..."
                  inputElement={<i className="fa fa-user text-[#c0c4cc]"></i>}
                  errorClassName="text-[#F56C6C] text-xs"
                />
              </FormControl>
              <FormControl name="password">
                <Input
                  width="auto"
                  className="!w-[98%] !max-w-none mb-[22px]"
                  inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                  placeholder="Mật khẩu..."
                  inputElement={<i className="fa fa-lock text-[#c0c4cc]"></i>}
                  errorClassName="text-[#F56C6C] text-xs"
                  type="password"
                />
              </FormControl>
              <Button
                type="submit"
                width="auto"
                size="m"
                containerClassName="mt-7 max-sm:!mt-3"
                label="ĐĂNG NHẬP"
                className="text-sm rounded-[20px] bg-[#fcd535] border-[#181e25] hover:bg-[#181e25] hover:border-[#181e25]"
                labelClassName="font-medium"
              />
            </Form>
          </Formik>
          <div className="mt-[20px] max-sm:!mt-3 text-[13.6px] text-[#757575] text-center">
            Bạn chưa có tài khoản?
          </div>
          <div className="text-[14.4px] text-[#fcd535] text-center font-medium">
            <span
              className="cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Đăng ký
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
