import { Account } from "./account.type";

export enum RechargeStatus {
  PENDING = "pending",
  SUCCESS = "success",
  CANCEL = "cancel",
  TIME_OUT = "time_out",
}

export interface Recharge {
  _id: string,
  money: number,
  content: string,
  status: RechargeStatus,
  bank: string,
  link_payment: string,
  account: Account,
  createdAt: string,
  updatedAt: string,
}

export interface RechargesResponse {
  current_page: number;
  total_page: number;
  count: number;
  data: Recharge[];
}
