export const SocketEvent = {
  KLINE: "KLINE",
  TRANSACTION: "transaction",
  TRANSACTION_TIMER: "transaction_timer",
  TRANSACTION_RESULT: "transaction_result",
  JOIN_ROOM: "join",
  MESSAGE: "message",
  THREAD: "thread",
  ANOTHER_MESSAGE: "anothor_message",
  SEEN: "seen",
};
