import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import Button from "../button";

function LogoutModal({ portalDialogRef }: PortalDialogProps) {
  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren={false}>
      <div className="w-[423px] py-7 px-6">
        <div className="text-xl text-center font-semibold">Thông báo</div>
        <div className="mt-8 text-center text-[#83898C]">
          Bạn có chắc chắn muốn đăng xuất?
        </div>
        <div className="mt-11 flex justify-center gap-4">
          <Button
            size="xs"
            label="Hủy"
            theme="secondary"
            width={180}
            className="!h-[51px] !leading-[51px]"
            onClick={portalDialogRef.close}
          />
          <Button
            size="xs"
            label="Đăng xuất"
            width={180}
            className="!h-[51px] !leading-[51px]"
            onClick={() => portalDialogRef.close({ isAccept: true })}
          />
        </div>
      </div>
    </Modal>
  );
}

export default LogoutModal;
