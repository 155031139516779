import { ChatImageRequest } from "@app/types";
import HttpService from "@core/services/http/http.service";
import { RequestContentType } from "@core/services/http/http.type";

class _ChatService {
  public uploadImage(body: ChatImageRequest) {
    return HttpService.post<any>("/images/upload", {
      requestContentType: RequestContentType.MULTIPART,
      body: { ...body },
    });
  }
}

const ChatService = new _ChatService();

export default ChatService;
