import LogoutModal from "@app/components/logout-modal";
import MenuLinkItem from "@app/components/menu-link-item";
import {
  ACCESS_TOKEN_KEY,
  Menu,
  PathnameUrl,
  SocketEvent,
} from "@app/constants";
import { clearLocalStorage } from "@app/constants/util.contst";
import AuthService from "@app/services/http/auth.service";
import { openPortalDialog } from "@app/services/modal.service";
import SocketService from "@app/services/socket.service";
import { clearUser } from "@app/store/auth/auth.action";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import { useEffect } from "react";
import { CiLogout } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

function MainLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscribeOnce } = useObservable();

  useEffect(() => {
    subscribeOnce(AuthService.verifyToken(), (data) => {
      if (data.statusCode !== 200) {
        dispatch(clearUser());
        StorageService.remove(ACCESS_TOKEN_KEY);
        navigate("/login");
      } else {
        SocketService.connect({ auth: { token: data.token } });
        SocketService.emit(SocketEvent.JOIN_ROOM, "admin");
      }
    });
  }, []);

  const onLogoutClick = () => {
    const logoutModalObs = openPortalDialog(LogoutModal);

    logoutModalObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        clearLocalStorage();
        dispatch(clearUser());
        navigate(PathnameUrl.LOGIN);
      }
    });
  };

  return (
    <div className="flex w-full h-[100vh]">
      <div className="fixed left-0 top-0 p-2 pt-5 flex flex-col transition-all w-[200px] h-full bg-[#1e2329] border-solid border-[#eee]">
        {Menu.map((item, index) => (
          <MenuLinkItem key={index} {...item} onMenuItemClick={() => {}} />
        ))}
        <div
          className="flex items-center gap-2 text-white cursor-pointer ml-2 mt-2"
          onClick={onLogoutClick}
        >
          {" "}
          <CiLogout className="text-[20px] text-center" /> Đăng xuất
        </div>
      </div>
      <div className="ml-[200px] w-full">
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
